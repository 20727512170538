import React from 'react';
import ReactDOM from "react-dom";
import {MultiSelect} from 'primereact/multiselect';
import Loader from "../Loader";
import ModalEdicaoPolo from "./ModalEdicaoPolo";
import {ToastContainer, toast} from 'react-toastify';
import ModalEdicaoMassa from "./ModalEdicaoMassa";
import {OverlayPanel} from "primereact/overlaypanel";
import {Button} from "react-bootstrap";
import ModalRemocaoMassa from "./ModalRemocaoMassa";


class Listagem extends React.Component {


    constructor() {
        super();
        this.state = {
            polos: [],
            loading: false,
            pagination: {
                total: 0,
                per_page: 20,
                current_page: 1,
                last_page: 1,
                from: 1,
                to: 0
            },
            filters: {
                cities: [],
                states: [],
            },
            categorias: [],
            estados: [
                {value: 'AC', label: 'Acre'},
                {value: 'AL', label: 'Alagoas'},
                {value: 'AP', label: 'Amapá'},
                {value: 'AM', label: 'Amazonas'},
                {value: 'BA', label: 'Bahia'},
                {value: 'CE', label: 'Ceará'},
                {value: 'DF', label: 'Distrito Federal'},
                {value: 'ES', label: 'Espírito Santo'},
                {value: 'GO', label: 'Goiás'},
                {value: 'MA', label: 'Maranhão'},
                {value: 'MT', label: 'Mato Grosso'},
                {value: 'MS', label: 'Mato Grosso do Sul'},
                {value: 'MG', label: 'Minas Gerais'},
                {value: 'PA', label: 'Pará'},
                {value: 'PB', label: 'Paraíba'},
                {value: 'PR', label: 'Paraná'},
                {value: 'PE', label: 'Pernambuco'},
                {value: 'PI', label: 'Piauí'},
                {value: 'RJ', label: 'Rio de Janeiro'},
                {value: 'RN', label: 'Rio Grande do Norte'},
                {value: 'RS', label: 'Rio Grande do Sul'},
                {value: 'RO', label: 'Rondônia'},
                {value: 'RR', label: 'Roraima'},
                {value: 'SC', label: 'Santa Catarina'},
                {value: 'SP', label: 'São Paulo'},
                {value: 'SE', label: 'Sergipe'},
                {value: 'TO', label: 'Tocantins'},
            ],
            currentEditing: null,
            showEditModal: false,
            errors: {},
            ids_selecionados: [],
            show_modal_acoes_em_grupo: false,
            show_modal_remocao_em_grupo: false,
            acoes_em_grupo_opcoes:{
                main_category: '',
                sub_category: '',
            },
            sub_categorias: [],
            id_delete: null,
            cidadesOptions: [],
            bairrosOptions: []
        }
    }

    handleChangeAcoesEmGrupo = async (e) => {
        await this.setState({
            acoes_em_grupo_opcoes: {
                ...this.state.acoes_em_grupo_opcoes,
                [e.target.name]: e.target.value
            }
        });
    }

    loadSubCategorias = async () => {
        try {
            try {
                await this.setLoading(true);
                const response = await axios.get( route('web.polos_geradores_subcategorias.index', {
                    categorias: this.state.filters.categorias
                }));

                this.setState({
                    sub_categorias: response.data
                });
            } catch (e) {
                console.log(e);
            }
        } catch (e) {
            console.log(e);
        }finally {
            await this.setLoading(false);
        }
    }

    handleSaveAcoesEmGrupo = async () => {
        try {

            this.setState({
                errors: {}
            });

            await this.setLoading(true);

            const response = await axios.post(route('web.view_gerenciamento_polos_geradores.update_many'), {
                ids: this.state.ids_selecionados,
                ...this.state.acoes_em_grupo_opcoes
            });


            if (response.status === 200) {
                toast.success(response.data.message);
                await this.loadPolos();
                await this.closeModalAcoesEmGrupo();

                this.setState({
                    acoes_em_grupo_opcoes:{
                        main_category: '',
                        sub_category: '',
                    }
                });
            }
        }
        catch (e) {
            console.log(e);
            if ( e.response && e.response.status === 422 ) {
                this.setState({
                    errors: e.response.data.errors
                });
            }
        }
        finally {
            await this.setLoading(false);
        }
    }

    openModalEdit = async () => {
        this.setState({
            showEditModal: true
        });
    }

    closeModalEdit = async () => {
        this.setState({
            showEditModal: false
        });
    }

    handleSave = async () => {
        try {

            this.setState({
                errors: {}
            });

            await this.setLoading(true);

            const response = await axios.put(route('web.view_gerenciamento_polos_geradores.update', this.state.currentEditing._id), this.state.currentEditing);

            if (response.status === 200) {
                toast.success('Polo atualizado com sucesso!');
                await this.loadPolos();
                await this.closeModalEdit();
            }


        } catch (e) {
            if (e.response && e.response.status === 422) {
                this.setState({
                    errors: e.response.data.errors
                });
            }
        } finally {
            await this.setLoading(false);
        }
    }

    loadCategorias = async () => {
        try {
            let response = await axios.get(route("polos-geradores-tipos"));

            if (response.status === 200) {
                let types = response.data.types;

                let options = [];

                types.forEach((type) => {
                    options.push({value: type, label: type});
                });

                this.setState({categorias: options});
            }
        } catch (e) {
            console.log(e);
        }
    };

    setLoading = async (loading) => {

        await this.setState({
            loading: loading
        });
    }


    async componentDidMount() {
       await Promise.all([
            this.loadPolos(),
            this.loadCategorias(),
            this.loadSubCategorias()
        ]);
    }

    getPolo = async (id) => {
        try {
            await this.setLoading(true);

            const response = await axios.get(route('gerenciamento-polos-geradores.show', id));

            this.setState({
                ...this.state,
                currentEditing: response.data
            })

        } catch (e) {
            console.log(e);
        } finally {
            await this.setLoading(false);
        }
    }

    loadPolos = async () => {
        try {

            await this.setLoading(true);

            const filters = this.prepareFiltersForRequest();

            console.log(filters);

            const response = await axios.get(route('gerenciamento-polos-geradores.index', filters));

            this.setState({
                polos: response.data.data,
                pagination: {
                    total: response.data.total,
                    per_page: response.data.per_page,
                    current_page: response.data.current_page,
                    last_page: response.data.last_page,
                    from: response.data.from,
                    to: response.data.to
                }
            });

        } catch (e) {
            console.log(e);
        } finally {
            await this.setLoading(false);
        }
    }

    setFilterValue = async (e) => {

        debugger;
        await this.setState({
            filters: {
                ...this.state.filters,
                [e.target.name]: e.target.value,

            },
            pagination: {
                ...this.state.pagination,
                current_page: 1
            }
        });

        if(e.target.name === 'states'){
            await this.loadCidades(e.target.value);
        }

        if(e.target.name === 'cities'){
            await this.loadBairros(e.target.value);
        }

        if( e.target.name === 'categorias' ){
            await this.loadSubCategorias(e.target.value);
        }
    }

    loadCidades = async (estados) => {
        try {
            await this.setLoading(true);

            const response = await axios.get(route('web.municipios.index', {
                ufs: estados
            }));

            this.setState({
                cidadesOptions: response.data
            });

        } catch (e) {
            console.log(e);
        } finally {
            await this.setLoading(false);
        }
    }

    loadBairros = async (cidades) => {
        try {
            await this.setLoading(true);

            const response = await axios.get(route('web.bairros_polos.index', {
                cidades: cidades,
                estados: this.state.filters.states
            }));

            this.setState({
                bairrosOptions: response.data
            });

        } catch (e) {
            console.log(e);
        } finally {
            await this.setLoading(false);
        }
    }

    handleChange = async (e) => {
        await this.setState({
            currentEditing: {
                ...this.state.currentEditing,
                [e.target.name]: e.target.value
            }
        });
    }

    prepareFiltersForRequest = () => {
        let filters = {};

        for (let key in this.state.filters) {
            let value = this.state.filters[key];

            if (!value) {
                continue;
            }

            if (typeof value === 'string') {
                filters[key] = value;
            }

            if (Array.isArray(value)) {
                filters[key] = value;
            }
        }

        filters.page = this.state.pagination.current_page || 1;

        return filters;
    }

    handleCheck = async (e) => {
        let id = e.target.value;

        if (e.target.checked) {
            await this.setState({
                ids_selecionados: [...this.state.ids_selecionados, id]
            });
        } else {
            await this.setState({
                ids_selecionados: this.state.ids_selecionados.filter((item) => item !== id)
            });
        }
    }

    checkAll = async (e) => {
        if (e.target.checked) {
            let ids = this.state.polos.map((polo) => polo._id);
            await this.setState({
                ids_selecionados: ids
            });
        } else {
            await this.setState({
                ids_selecionados: []
            });
        }
    }

    showModalAcoesEmGrupo = async () => {
        this.setState({
            show_modal_acoes_em_grupo: true
        });
    }

    showModalDelecaoEmGrupo = async () => {
        this.setState({
            show_modal_remocao_em_grupo: true
        });
    }

    closeModalAcoesEmGrupo = async () => {
        this.setState({
            show_modal_acoes_em_grupo: false
        });
    }

    closeModalRemocaoEmGrupo = async () => {
        this.setState({
            show_modal_remocao_em_grupo: false
        });
    }

    formatIntegerNumber = (number) => {
        return new Intl.NumberFormat('pt-BR').format(number);
    }

    deletePolo = async () => {
        try {
            await this.setLoading(true);

            const response = await axios.delete(route('gerenciamento-polos-geradores.destroy', this.state.id_delete));

            if (response.status === 200) {
                toast.success('Polo deletado com sucesso!');
                this.op.hide();
                await this.loadPolos();

                this.setState({
                   ...this.state,
                     id_delete: null
                });
            }

        }catch (e){
            console.log(e);
        }finally {
            await this.setLoading(false);
        }
    }

    handleRemocaoEmGrupo = async () => {
        try {
            await this.setLoading(true);

            const response = await axios.post(route('gerenciamento-polos-geradores.destroy_many'), {
                ids: this.state.ids_selecionados
            });

            if (response.status === 200) {
                toast.success(response.data.message);
                await this.loadPolos();
                await this.closeModalRemocaoEmGrupo();
            }

        } catch (e) {
            console.log(e);
        } finally {
            await this.setLoading(false);
        }
    }

    render() {
        return (

            <div className="card">

                {this.state.loading && (
                    <Loader/>
                )}

                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{width: "50%", zIndex: 9999999, fontWieght: 'bold'}}
                />

                <ModalEdicaoPolo
                    show={this.state.showEditModal}
                    handleClose={this.closeModalEdit}
                    polo={this.state.currentEditing}
                    categorias={this.state.categorias}
                    sub_categorias={this.state.sub_categorias}
                    handleChange={this.handleChange}
                    handleSave={this.handleSave}
                    errors={this.state.errors}
                />

                <ModalEdicaoMassa
                    opcoes={this.state.acoes_em_grupo_opcoes}
                    show={this.state.show_modal_acoes_em_grupo}
                    handleClose={this.closeModalAcoesEmGrupo}
                    categorias={this.state.categorias}
                    sub_categorias={this.state.sub_categorias}
                    handleChange={this.handleChangeAcoesEmGrupo}
                    handleSave={this.handleSaveAcoesEmGrupo}
                    errors={this.state.errors}
                />

                <ModalRemocaoMassa
                    show={this.state.show_modal_remocao_em_grupo}
                    handleClose={this.closeModalRemocaoEmGrupo}
                    ids_selecionados={this.state.ids_selecionados}
                    handleSave={this.handleRemocaoEmGrupo}
                    errors={this.state.errors}
                />


                <div className="card-body">
                    <form>
                        <div className="form-group row">
                            <div className="col-12 col-md-4 col-sm-4 col-lg-4">
                                <label htmlFor="title">Título</label>
                                <input type="text" id="title" name="title"
                                       onChange={this.setFilterValue}
                                       value={this.state.filters?.title}
                                       className="form-control"/>
                            </div>

                            <div className="col-12 col-md-4 col-sm-4 col-lg-4">
                                <label htmlFor="formatted_address">Categoria</label> <br/>
                                <MultiSelect
                                    name="categorias"
                                    optionLabel="label"
                                    value={this.state.filters.categorias}
                                    options={this.state.categorias}
                                    onChange={this.setFilterValue}
                                    filter={true}
                                    display="chip"
                                    placeholder="Selecione a categoria"
                                    selectedItemsLabel="{0} selecionados"
                                    style={{width: '100%'}}
                                    maxSelectedLabels={3}
                                    showClear={true}
                                />
                            </div>

                            <div className="col-12 col-md-4 col-sm-4 col-lg-4">
                                <label>Sub-Categoria</label>
                                <MultiSelect
                                    name="sub_categorias"
                                    optionLabel="label"
                                    value={this.state.filters.sub_categorias}
                                    blockScroll={true}
                                    appendTo={document.body}
                                    options={this.state.sub_categorias}
                                    onChange={this.setFilterValue}
                                    placeholder="Selecione Uma Sub-Categoria"
                                    selectedItemsLabel="{0} selecionados"
                                    maxSelectedLabels={3}
                                    style={{width: '100%'}}
                                    display="chip"
                                    filter={true}
                                    lazy={true}
                                    showClear={true}
                                    disabled={this.state.filters.categorias?.length === 0}
                                />
                            </div>

                        </div>

                        <div className="form-group row">
                            <div className="col-12 col-md-4 col-sm-4 col-lg-4">
                                <label htmlFor="state">Estado</label>
                                <MultiSelect
                                    name="states"
                                    optionLabel="label"
                                    value={this.state.filters.states}
                                    options={this.state.estados}
                                    onChange={this.setFilterValue}
                                    filter={true}
                                    display="chip"
                                    placeholder="Selecione o estado"
                                    selectedItemsLabel="{0} selecionados"
                                    style={{width: '100%'}}
                                    maxSelectedLabels={3}
                                />
                            </div>

                            <div className="col-12 col-md-4 col-sm-4 col-lg-4">
                                <label htmlFor="city">Cidade</label>
                                <MultiSelect
                                    name="cities"
                                    optionLabel="label"
                                    value={this.state.filters.cities}
                                    options={this.state.cidadesOptions}
                                    onChange={this.setFilterValue}
                                    filter={true}
                                    display="chip"
                                    placeholder="Selecione"
                                    selectedItemsLabel="{0} selecionados"
                                    style={{width: '100%'}}
                                    maxSelectedLabels={3}
                                    disabled={this.state.filters.states?.length === 0}
                                />
                            </div>

                            <div className="col-12 col-md-4 col-sm-4 col-lg-4">
                                <label htmlFor="city">Bairro</label>
                                <MultiSelect
                                    name="neighborhoods"
                                    optionLabel="label"
                                    value={this.state.filters.neighborhoods}
                                    options={this.state.bairrosOptions}
                                    onChange={this.setFilterValue}
                                    filter={true}
                                    display="chip"
                                    placeholder="Selecione"
                                    selectedItemsLabel="{0} selecionados"
                                    style={{width: '100%'}}
                                    maxSelectedLabels={3}
                                    disabled={this.state.filters.cities?.length === 0}
                                />
                            </div>

                        </div>

                        <div className="form-group row">
                            <div className="col-12 col-md-6 col-sm-6 col-lg-6">
                                <button className="btn btn-sm btn-block btn-success" onClick={async (e) => {
                                    e.preventDefault();
                                    await this.loadPolos();
                                }}>
                                    <i className="fa fa-search"></i> &nbsp;
                                    Pesquisar
                                </button>
                            </div>

                            <div className="col-12 col-md-6 col-sm-6 col-lg-6">
                                <button className="btn btn-sm btn-block btn-primary" onClick={async (e) => {
                                    e.preventDefault();
                                    await this.cleanFiltros();
                                }}>
                                    <i className="fa fa-eraser"></i> &nbsp;
                                    Limpar Filtros
                                </button>
                            </div>

                        </div>


                    </form>
                    <hr/>

                    <div className="d-flex justify-content-between align-items-center">
                        <p className="text-muted p-1">
                            Exibindo
                            de {this.formatIntegerNumber(this.state.pagination.from)} até {this.formatIntegerNumber(this.state.pagination.to)} de {this.formatIntegerNumber(this.state.pagination.total)} registros
                        </p>

                        <div>

                            <div className="dropdown">
                                <button className="btn btn-secondary dropdown-toggle" type="button"
                                        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                        disabled={this.state.ids_selecionados.length === 0}
                                        aria-expanded="false">
                                    Ações em Grupo
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <button className="dropdown-item" onClick={this.showModalAcoesEmGrupo}>
                                        <i className="fa fa-edit"></i> &nbsp;
                                        Atualizar
                                    </button>

                                    <button className="dropdown-item" onClick={this.showModalDelecaoEmGrupo}>
                                        <i className="fa fa-trash"></i> &nbsp;
                                        Excluir
                                    </button>
                                </div>
                            </div>

                            {/*<button className="btn btn-sm btn-primary" onClick={this.showModalAcoesEmGrupo}
                                    disabled={this.state.ids_selecionados.length === 0}
                            >Ações em Grupo
                            </button>*/}
                        </div>
                    </div>

                    <OverlayPanel
                        appendTo={document.body}
                        ref={(el) => this.op = el}>
                        <p>
                            Tem certeza que deseja excluir esse registro?
                        </p>
                        <button className="btn btn-danger btn-sm"
                                onClick={async (e) => {
                                    await this.deletePolo();
                                }}
                        >Sim</button> &nbsp;
                        <button className="btn btn-default btn-sm"
                                onClick={(e) => this.op.hide(e)}
                        >Cancelar</button>
                    </OverlayPanel>


                    <div style={{overflowY: "auto"}}>
                        <table className="table table-bordered table-striped">
                            <thead>
                            <tr style={{backgroundColor: '#5B7537', color: 'white'}}>
                                <th style={{color: 'white'}}>
                                    <input type="checkbox"
                                           className="checkbox"
                                           checked={this.state.ids_selecionados.length === this.state.polos.length}
                                           onClick={this.checkAll}/>
                                </th>
                                <th style={{color: 'white'}}>Título</th>
                                <th style={{color: 'white'}}>Endereço</th>
                                <th style={{color: 'white'}}>Bairro</th>
                                <th style={{color: 'white'}}>Cidade</th>
                                <th style={{color: 'white'}}>Estado</th>
                                <th style={{color: 'white'}}>Categoria</th>
                                <th style={{color: 'white'}}>Sub-Cateria</th>
                                <th style={{color: 'white'}}>Ações</th>
                            </tr>
                            </thead>

                            <tbody>
                            {this.state.polos.map((polo, index) => {
                                return (
                                    <tr key={polo._id}>
                                        <td>
                                            <input type="checkbox" name="ids_selecionados"
                                                   className="checkbox"
                                                   checked={this.state.ids_selecionados.includes(polo._id)}
                                                   onChange={this.handleCheck}
                                                   value={polo._id}/>
                                        </td>
                                        <td>{polo.title}</td>
                                        <td>{polo.formatted_address}</td>
                                        <td>{polo.neighborhood}</td>
                                        <td>{polo.city}</td>
                                        <td>{polo.state}</td>
                                        <td>{polo.main_category}</td>
                                        <td>{polo.sub_category}</td>
                                        <td>
                                            <div className="d-flex justify-content-between align-items-center align-content-center ">
                                                <a href="#" className="btn btn-sm btn-success"
                                                   onClick={async (e) => {
                                                       e.preventDefault();
                                                       await this.getPolo(polo._id);

                                                       await this.openModalEdit();
                                                   }}
                                                >
                                                    <i className="fa fa-edit"></i>
                                                </a>
                                                &nbsp;

                                                <button className="btn btn-sm btn-danger" onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({
                                                        id_delete: polo._id
                                                    });
                                                    this.op.toggle(e);
                                                }}>
                                                    <i className="fa fa-trash"></i>
                                                </button>


                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>

                        </table>
                    </div>

                </div>

                <div className="card-footer">
                    <nav>
                        <ul className="pagination d-flex justify-content-center align-items-center">
                            <li className="page-item">
                                {this.state.pagination.current_page > 1 &&
                                    <a href="#" className="page-link" onClick={async (e) => {
                                        e.preventDefault();
                                        this.setState({
                                            pagination: {
                                                ...this.state.pagination,
                                                current_page: this.state.pagination.current_page - 1
                                            }
                                        })
                                        await this.loadPolos();
                                    }}>
                                        Anterior
                                    </a>}
                            </li>
                            <li className="page-item">
                                {this.state.pagination.current_page < this.state.pagination.last_page &&
                                    <a href="#" className="page-link" onClick={async (e) => {
                                        e.preventDefault();
                                        this.setState({
                                            pagination: {
                                                ...this.state.pagination,
                                                current_page: this.state.pagination.current_page + 1
                                            }
                                        })
                                        await this.loadPolos();
                                    }}>
                                        Próxima
                                    </a>}
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        );
    }

    async cleanFiltros() {
        await this.setState({
            filters: {}
        });

        await this.loadPolos();
    }
}


if (document.getElementById('js-polos-geradores-gerenciamento')) {
    ReactDOM.render(<Listagem/>, document.getElementById('js-polos-geradores-gerenciamento'));
}

