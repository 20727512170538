import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';
import IntlCurrencyInput from "react-intl-currency-input"
import { SelectButton } from 'primereact/selectbutton';
import Pin from "./Pin/Pin";
import Slider from '@material-ui/core/Slider';
import { rangeRight } from 'lodash';


var currencyConfig = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                //style: "currency",
                //currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
        },
    },
};

var currencyConfigInteger = {
    locale: "pt-BR",
    formats: {
        number: {
            BRL: {
                //style: "currency",
                //currency: "BRL",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            },
        },
    },
};

const labels = {
    "area_media": "Área média",
    "ticket_medio_metro": "Ticket Médio R$/m²",
    "total_unidades": "Oferta Inicial",
    "oferta_final": "Estoque",
    "padroes": "Padrão",
}



class ModalRanges extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            min: 0,
            max: null,
            type: null,
            marker: null,
            errorMessage: null,
            iconsImages: [],
            padroes: [],
            markerType: 'color',
            markersTypes: [
                { label: 'Cor', value: 'color' },
                { label: 'Imagem', value: 'image' }
            ],
            uploading: false,
            id: null,
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount = async () => {
        let iconsUrls = await axios.get(route('web.marcadores_selecionaveis.index'));

        this.setState({
            iconsImages: iconsUrls.data
        });

    }

    handleMarkerChange = (selectedMarker) => {
        this.setState({
            ...this.state,
            marker: selectedMarker
        });
    }

    submit = async () => {

    }

    openModal(area) {
        this.setState({
            modalIsOpen: true,
            area: area
        });
    }

    handleValorMinimumChange = async (event, value, maskedValue) => {

        event.preventDefault();
        await this.setState({
            ...this.state,
            min: value

        });
    }

    handleValorMaximumChange = async (event, value, maskedValue) => {

        event.preventDefault();
        await this.setState({
            ...this.state,
            max: value

        });
    }

    handleTipoChange = async (event) => {
        const { type } = this.state;

        if (type?.value === 'padroes' && event.value !== 'padroes') {
            await this.setState((prevState) => ({
                ...prevState,
                max: 0,
                min: 0,
            }));
        }

        await this.setState((prevState) => ({
            ...prevState,
            type: event,
        }));

        if (event.value === 'padroes') {
            await this.setState((prevState) => ({
                ...prevState,
                max: 99999999,
                min: 1,
            }));
        }
    };


    needsIntegerInputRange = () => {
        return this.state.type?.value === "total_unidades" || this.state.type?.value === "oferta_final";
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
            area: null
        });
    }

    addRange = async () => {

        if (this.state.max == null) {
            this.setState({
                ...this.state,
                max: 99999999
            });

            return;
        }


        if (this.state.min == null || this.state.type == null || this.state.marker == null) {
            console.log(this.state);
            this.setState({
                ...this.state,
                errorMessage: "Preencha todos os campos"
            });

            return;
        }


        if (this.state.max < this.state.min) {
            this.setState({
                ...this.state,
                errorMessage: "Valor máximo deve ser maior que o mínimo"
            });

            return;
        }

        let padroes = [];

        if (this.state.padroes) {
            padroes = this.state.padroes.map((padrao) => { return padrao.value })
        }

        let rangeTypeIsDiff = this.props.ranges.find(range => {
            return range.type != this.state.type.value;
        });

        if (rangeTypeIsDiff) {
            this.setState({
                ...this.state,
                errorMessage: "Já existe um range para " + labels[this.props.ranges[0].type]
            });

            return;
        }

        let rangeExitis = this.props.ranges.find(range => {
            return range.min === this.state.min
                && range.max === this.state.max
                && range.type === this.state.type.value
                && range.padroes.toString() == padroes.toString()
                && this.state.id == null;
        });

        if (rangeExitis) {
            this.setState({
                ...this.state,
                errorMessage: "Já existe um range com esses valores"
            });

            return;
        }


        let markerExists = this.props.ranges.find(range => {
            return range.marker === this.state.marker.value;
        });

        if (markerExists) {
            this.setState({
                ...this.state,
                errorMessage: "Já existe um range com esse marcador"
            });

            return;
        }


        let overlaps = this.props.ranges.find(range => {
            return (
                this.state.id !== range.id &&
                range.padroes.some(padrao => padroes.includes(padrao)) &&
                (
                    (this.state.min >= range.min && this.state.min <= range.max) ||
                    (this.state.max >= range.min && this.state.max <= range.max)
                )
            );
        });

        if (overlaps) {
            this.setState({
                ...this.state,
                errorMessage: "O range se sobrepõe a outro range"
            });

            return;
        }


        let range = {
            min: this.state.min,
            max: this.state.max,
            type: this.state.type.value,
            marker: this.state.marker,
            padroes: padroes,
            id: this.state.id ?? this.generateRandomString()
        }
        if (this.state.id) {
            await this.props.updateRange(range);
        }
        else {
            await this.props.addRange(range);
        }

        await this.setState({
            ...this.state,
            min: this.state.type.value == 'padroes' ? 1 : null,
            max: null,
            errorMessage: null,
            marker: null,
            padroes: [],
            id: null,
        });

    }

    updateRange = async (range) => {

        const type = {
            area_media: { value: 'area_media', label: 'Área Média' },
            ticket_medio_metro: { value: 'ticket_medio_metro', label: 'Ticket Médio R$/m²' },
            total_unidades: { value: 'total_unidades', label: 'Oferta Inicial' },
            oferta_final: { value: 'oferta_final', label: 'Estoque' },
            padroes: { value: 'padroes', label: 'Padrão' },
        };

        let padroes = []

        if (range.padroes) {
            range.padroes.map((padrao) => {
                padroes.push({ value: padrao, label: padrao })
            });
        }
        await this.setState({
            ...this.state,
            min: range.min,
            max: range.max,
            type: type[range.type],
            marker: range.marker,
            padroes: padroes,
            id: range.id
        })

        console.log(this.state);

    }

    generateRandomString = () => {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    uploadFile = async (event) => {

        event.preventDefault();

        try {

            await this.setState({
                uploading: true,
                errorMessage: null
            });

            let file = event.target.files[0];
            let formData = new FormData();
            formData.append('file', file);
            let response = await axios.post(route('custom-marker-upload'), formData);

            this.setState({
                ...this.state,
                iconsImages: [response.data[0].url, ...this.state.iconsImages],
                marker: {
                    ...this.state.marker,
                    value: response.data[0].url,
                }

            });
        } catch (e) {
            if (e.response && e.response.status === 422) {
                this.setState({
                    errorMessage: e.response.data.errors.file[0] + ' ' + e.response.data.errors.file[1]
                });
            }
        } finally {
            this.setState({
                uploading: false,
                fileSelected: undefined
            });
        }
    }

    getPadroes = () => {
        let padroesVerticais = [
            { value: 'Todos', label: 'Selecionar Todos' },
            { value: 'Especial', label: 'Especial' },
            { value: 'Econômico', label: 'Econômico' },
            { value: 'Standard', label: 'Standard' },
            { value: 'Médio', label: 'Médio' },
            { value: 'Alto', label: 'Alto' },
            { value: 'Luxo', label: 'Luxo' },
            { value: 'Super Luxo', label: 'Super Luxo' },
        ];

        let padroesHorizontais = [
            { value: 'Condomínio de Casas/Sobrados', label: 'Condomínio de Casas/Sobrados' },
            { value: 'Condomínio de Chácaras', label: 'Condomínio de Chácaras' },
            { value: 'Loteamento Aberto', label: 'Loteamento Aberto' },
            { value: 'Loteamento Fechado', label: 'Loteamento Fechado' },
        ];

        let padroes = [...padroesVerticais, ...padroesHorizontais];

        const { ranges } = this.props;

        // Filtrar os padrões que já estão em `ranges.padroes`
        const filteredPadroes = padroes.filter(padrao => {
            return !ranges.some(range => range.padroes.includes(padrao.value));
        });

        return filteredPadroes;
    };


    handlePadraoChanged = async (e) => {
        if (e === null) {
            this.setState({
                ...this.state, padroes: [],
            });
            return;
        }

        let padroes = [];
        e.forEach((padrao) => {
            if (padrao.value === 'Todos') {

                padroes = this.getPadroes().filter(padrao => {
                    return padrao.value != 'Todos';
                });

                return;
            }

            if (!padroes.includes(padrao)) {
                padroes.push(padrao)
            }
        })

        await this.setState({
            ...this.state, padroes: padroes,
        });

        console.log(this.state);
    }


    render() {

        let configRangeInput = currencyConfig;

        if (this.needsIntegerInputRange()) {
            configRangeInput = currencyConfigInteger;
        }

        return (
            <div style={{ zIndex: '9999' }}>
                <Modal show={this.props.show} onHide={this.props.closeModal} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Criar Ranges</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12">
                                    {this.state.errorMessage &&
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.errorMessage}
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-12 col-md-2 col-sm-2 col-lg-2">
                                    <label>Tipo de Marcador:</label>
                                    <SelectButton
                                        optionLabel="label"
                                        value={this.state.markerType}
                                        options={this.state.markersTypes}
                                        onChange={(e) => {
                                            this.setState({
                                                markerType: e.value,
                                                marker: {
                                                    value: this.state.marker?.value,
                                                    type: e.value
                                                }

                                            })
                                        }}></SelectButton>

                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-12 col-md-3 col-sm-3 col-lg-3">
                                    <label>Range Por:</label>
                                    <Select
                                        value={this.state.type}
                                        placeholder="Selecione o tipo"
                                        onChange={this.handleTipoChange}
                                        options={[
                                            { value: 'area_media', label: 'Área Média' },
                                            { value: 'ticket_medio_metro', label: 'Ticket Médio R$/m²' },
                                            { value: 'total_unidades', label: 'Oferta Inicial' },
                                            { value: 'oferta_final', label: 'Estoque' },
                                            { value: 'padroes', label: 'Padrão' },
                                        ]}
                                    />
                                </div>

                                {(this.state.type?.value != 'padroes' || !this.state.type) && <>
                                    <div className="col-12 col-md-3 col-sm-3 col-lg-3">
                                        <label>A partir de</label>
                                        <IntlCurrencyInput
                                            currency="BRL"
                                            config={configRangeInput}
                                            value={this.state.min}
                                            onChange={this.handleValorMinimumChange}
                                            style={{ padding: '3px' }}
                                            className="valor form-control"

                                        />
                                    </div>

                                    <div className="col-12 col-md-3 col-sm-3 col-lg-3">
                                        <label>Até</label>
                                        <IntlCurrencyInput
                                            currency="BRL"
                                            config={configRangeInput}
                                            onChange={this.handleValorMaximumChange}
                                            style={{ padding: '3px' }}
                                            className="valor form-control"
                                            value={this.state.max}
                                        />
                                    </div>
                                </>}


                                {this.state.type?.value == 'padroes' && <>
                                    <div className="col-12 col-md-3 col-sm-3 col-lg-3">
                                        <label>Padrão</label>
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderRadius: 20,
                                                }),
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: "#F2F2F2",
                                                    primary: "#5B7537",
                                                },
                                            })}
                                            placeholder="Selecione"
                                            options={this.getPadroes()}
                                            value={this.state.padroes}
                                            onChange={(value) => this.handlePadraoChanged(value)}
                                            isMulti={true}
                                        />
                                    </div>
                                </>}
                            </div>

                            {this.state.markerType === 'color' &&
                                <div className="form-group row">
                                    <div className="col-12 col-md-2 col-sm-2 col-lg-2">
                                        <img style={{ width: '200px' }} src="/img/marker_parts.png" alt="partes do marcador" />
                                    </div>
                                    <div className="col-12 col-md-10 col-sm-10 col-lg-10">
                                        <div className="row form-group">
                                            <div className="col-12 col-md-2 col-sm-2 col-lg-2">
                                                <label>Cor de Fundo</label>
                                                <input type="color" value={this.state.marker?.background || '#fff'} onChange={(e) => {

                                                    this.setState({
                                                        ...this.state,
                                                        marker: {
                                                            ...this.state.marker,
                                                            background: e.target.value,
                                                        }
                                                    });
                                                }} />

                                            </div>
                                            <div className="col-12 col-md-2 col-sm-2 col-lg-2">
                                                <label>Cor da Borda</label> &nbsp;
                                                <input type="color"
                                                    value={this.state.marker?.borderColor || '#000'}
                                                    onChange={e => this.setState({
                                                        ...this.state,
                                                        marker: {
                                                            ...this.state.marker,
                                                            borderColor: e.target.value,
                                                        }
                                                    })}
                                                />
                                            </div>
                                            <div className="col-12 col-md-2 col-sm-2 col-lg-2">
                                                <label>Cor do Glifo</label> &nbsp;
                                                <input type="color"
                                                    value={this.state.marker?.glyphColor || '#000'}
                                                    onChange={e => this.setState({
                                                        ...this.state,
                                                        marker: {
                                                            ...this.state.marker,
                                                            glyphColor: e.target.value,
                                                            type: 'color'
                                                        }
                                                    })}
                                                />
                                            </div>

                                            <div className="col-12 col-md-2 col-sm-2 col-lg-2">
                                                <label>Escala</label> &nbsp;
                                                <Slider
                                                    value={this.state.marker?.scale || 1}
                                                    aria-labelledby="discrete-slider-small-steps"
                                                    step={0.1}
                                                    marks
                                                    min={0.1}
                                                    max={2}
                                                    valueLabelDisplay="on"
                                                    onChange={(e, newValue) => {

                                                        this.setState({
                                                            ...this.state,
                                                            marker: {
                                                                ...this.state.marker,
                                                                scale: newValue
                                                            }
                                                        });

                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {this.state.markerType === 'image' &&
                                <div className="form-group row">
                                    <div className="col-md-12 col-sm-12 col-lg-12">
                                        <label>Selecione uma imagem disponível ou envie uma imagem de sua escolha</label> <br />
                                        <div style={{ marginBottom: '15px' }}>
                                            <input className="form-control-file"
                                                type="file"
                                                disabled={this.state.uploading}
                                                onChange={async (event) => {
                                                    event.persist();
                                                    await this.uploadFile(event);
                                                }} />
                                            {this.state.uploading && <div className="alert alert-info">Enviando arquivo...</div>}
                                        </div>
                                        <div className="d-flex justify-content-center align-itens-center flex-wrap border border-primary"
                                            style={{ height: '172px', overflowX: 'scroll' }}>
                                            {this.state.iconsImages.map((icon, index) => {
                                                let className = this.state.marker?.value === icon ? 'selectable-icons-div selectable-icons-div-selected bg-success' : 'selectable-icons-div';
                                                return (
                                                    <div
                                                        role="button"
                                                        className={className}
                                                        onClick={() => this.setState({
                                                            ...this.state,
                                                            marker: { value: icon, type: 'image' }
                                                        })}
                                                        key={index}>
                                                        <img src={icon} alt={icon} style={{ width: '25px' }} />
                                                        <input type="hidden" id={icon} name="icon" value={icon} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="form-group row">
                                <div className="col-md-12 col-sm-12 col-lg-12" style={{ marginTop: '10px' }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <button className="btn btn-xs btn-primary button-brain btn-round"
                                            onClick={this.addRange}>
                                            {this.state.id == null ? 'Adicionar' : 'Atualizar'} Range
                                        </button>
                                        <button
                                            className="btn btn-xs btn-success border-brain background-brain btn-round"
                                            onClick={() => {
                                                this.props.replaceMarkers();
                                                this.props.closeModal();
                                            }}>
                                            Aplicar
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div style={{ height: '250px', overflowY: 'scroll' }}>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>A partir de</th>
                                                    <th>Até</th>
                                                    <th>Tipo</th>
                                                    <th>Padrões</th>
                                                    <th>Marcador</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.props.ranges.map((range, index) => {

                                                    let hexaBackground = null;

                                                    if (range.marker.type === 'color') {
                                                        hexaBackground = range.marker.background;
                                                    }

                                                    let icon = range.marker.type === 'image' ? range.marker.value : null;

                                                    return (
                                                        <tr key={index}>
                                                            <td>{() => {
                                                                if (range.type.value != 'padrao') {
                                                                    return Number(range.min).toLocaleString()
                                                                }
                                                                return null
                                                            }}</td>
                                                            <td>{(() => {
                                                                if (range.max === 99999999) {
                                                                    return null
                                                                }
                                                                return Number(range.max).toLocaleString();
                                                            })()}</td>
                                                            <td>{labels[range.type]}</td>
                                                            <td>{range.padroes.toString()}</td>
                                                            <td>
                                                                {hexaBackground && <> <Pin backgroundColor={hexaBackground}
                                                                    borderColor={range.marker.borderColor}
                                                                    glyphColor={range.marker.glyphColor}
                                                                />
                                                                </>}
                                                                {icon &&
                                                                    <img src={icon} alt={icon}
                                                                        style={{ width: '25px' }} />}
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-xs btn-danger btn-sm"
                                                                    onClick={() => this.props.removeRange(range)}>
                                                                    <i className="fa fa-trash"></i>
                                                                </button>
                                                                <button className="btn btn-xs btn-danger btn-sm"
                                                                    onClick={() => this.updateRange(range)}>
                                                                    <i className="fa fa-pen"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )

    }
}

export default ModalRanges;
